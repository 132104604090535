<template >
    <div class="error-template">
        <div class="errore-cont">
            <img src="../assets/bandera-offside.png" alt="" class="bandera">
            <h3 class="offside">¡FUERA DE LUGAR!</h3>
            <h1 class="cuatrocientoscuatro">404</h1>
            <h4 class="textifero-1">Parece que algo salió mal.</h4>
            <h5 class="textifero-2">La página que estás buscando no está disponible o no la encontramos.</h5>
            <div class="related-page-cont">
                <a href="/">INICIO</a>
                <p>|</p>
                <a href="/tienda">TIENDA</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
 .error-template {
     background: url('../assets/fondiu1.png');
     height: 53.333vw;
     width: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}.errore-cont {
    margin-top: 9.531vw;
}
.bandera{
    position: relative;
    animation: efectaje 3s infinite alternate!important;
}
@keyframes efectaje {
  from {
    transform: rotate(-10deg);
    }
  to {
    transform: rotate(5deg);}
}
@media (max-width: 768px) {
 .error-template {
     background: url('../assets/fondiu2.png');
     height: 219.261vw;
     width: 100%;
    background-size: 100%;
}   .errore-cont {
    margin-top: 75.121vw;
}
}
</style>